<template>
  <div class="fill-height start-div white--text" :style="bgStyles">
    <v-container fill-height class="pa-0">
      <div class="text-div d-md-flex flex-column justify-center fill-height pa-5 pt-10 mx-0 pa-lg-10" :class="{ 'flex-fill': $vuetify.breakpoint.smAndDown }">
        <div class="my-auto">
          <div class="pb-12">
            <img :src="require('@/assets/logo.svg')" alt="MESI logo" width="200" />
            <h1 class="font-weight-regular mb-8" style="font-size: 1.7rem">{{ $t('appName') }}</h1>
            <h2 class="font-weight-light headline">{{ $t('startBuild') }} <span class="font-weight-bold">{{ $t('startmTablet') }}</span> {{ $t('startForPractice') }}</h2>
          </div>
          <div v-if="!languageSelected" class="mt-10">
            <LanguageSelect @language="selectLanguage" />
          </div>
          <!-- <template v-else>
            <div class="mt-10">
              <PreSelect :systemData="systemData" :fieldOfCareData="fieldOfCareData" :lang="lang" />
            </div>
            <div class="d-flex">
              <v-btn text class="text-right ml-auto" color="white" @click="skip"><span class="">{{ $t('startCreateCustom') }}</span><v-icon right>mdi-chevron-right</v-icon></v-btn>
            </div>
          </template> -->
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import fieldOfCareData from '@/data/pl/fields.json'
// import PreSelect from '@/components/PreSelect.vue'
import LanguageSelect from '@/components/LanguageSelect.vue'
import { SUPPORTED_LOCALES } from '../locales/locales.js'
import { mapGetters } from 'vuex'

export default {
  name: 'Start',
  jsonld () {
    const items = [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: 'https://configurator.mesimedical.com/start'
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Start'
      }
    ]
    return {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'WebPage',
          '@id': 'https://configurator.mesimedical.com/start',
          url: 'https://configurator.mesimedical.com/start',
          name: 'Start',
          inLanguage: SUPPORTED_LOCALES.find(el => el.code === this.$i18n.locale).iso,
          isPartOf: {
            '@type': 'WebSite',
            '@id': 'https://configurator.mesimedical.comt' + '/#website'
          },
          description: 'App start: language selection',
          breadcrumb: {
            '@id': 'https://configurator.mesimedical.com/start' + '/#breadcrumb'
          }
        },
        {
          '@type': 'BreadcrumbList',
          '@id': 'https://configurator.mesimedical.com/start' + '/#breadcrumb',
          itemListElement: items
        }
      ]
    }
  },
  components: {
    /* PreSelect, */ LanguageSelect
  },
  data: function () {
    return {
      bgStyles: {
        backgroundImage: 'url(' + require('@/assets/jumbo.jpg') + ')'
      },
      lang: 'pl',
      languageSelected: true
    }
  },
  mounted () {
    this.$localStorage.set('mConf_startPageShown', true)
    this.selectLanguage('pl')
  },
  computed: {
    ...mapGetters([
      'selectedField'
    ]),
    fieldOfCareData () {
      return fieldOfCareData
    },
    systemDataOk () {
      return typeof this.systemData !== 'undefined'
    },
    systemData () {
      if (typeof this.selectedField === 'undefined') {
        return {}
      }
      return this.getObjectWithIDFromArr(fieldOfCareData, this.selectedField)
    }
  },
  methods: {
    skip () {
      if (this.saEvent) {
        this.saEvent('SkipFieldSelectOnStart')
      }
      this.trackEvent('SkipFieldSelectOnStart', 'ButtonClick')
      this.$router.push({ path: '/' })
    },
    selectLanguage (lang) {
      if (this.saEvent) {
        this.saEvent('language', { language: lang })
      }
      this.$i18n.locale = lang
      this.lang = lang
      this.languageSelected = true
      this.skip()
    }
  }
}
</script>

<style lang="scss" scoped>
.start-div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .text-div {
    background: rgba(0, 0, 0, 0.24);
    max-width: 550px;
  }
}

.c-divider {
  background: white;
  height: 5px;
}
</style>
