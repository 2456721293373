export const SUPPORTED_LOCALES = [
  {
    code: 'pl',
    base: '',
    flag: 'pl',
    name: 'PL',
    translations: '/translations/pl.json',
    iso: 'pl-PL'
  }
]
