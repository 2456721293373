import fieldOfCareData from '@/data/pl/fields.json'
import mixins from '@/mixins.js'

const CHANGE_FIELD = (context, fieldID) => {
  context.commit('RESET_STATE')
  context.commit('GENERATE_BY_ID_OBJECTS')
  context.commit('UPDATE_SELECTED_FIELD', fieldID)

  const selectedField = mixins.getObjectWithIDFromArr(fieldOfCareData, fieldID)

  const extModsArray = selectedField.extensionsModules.included

  if (!extModsArray || extModsArray.length < 1) {
    return
  }
  for (const extMod of extModsArray) {
    context.commit('TOGGLE_EXTENSION_MODULE', extMod)
  }
}

const ADJUST_ABI_TBI = context => {
  context.commit('ADJUST_ABI_TBI')
  context.commit('SYNC_DATA')
}

const READJUST_ABI_TBI = context => {
  context.commit('READJUST_ABI_TBI')
  context.commit('SYNC_DATA')
}

const TOGGLE_EXTENSION_MODULE = (context, extModID, addOnly = false) => {
  context.commit('TOGGLE_EXTENSION_MODULE', extModID, addOnly)
  context.commit('SYNC_DATA')
}

const TOGGLE_APPLICATION = (context, appID) => {
  context.commit('TOGGLE_APPLICATION', appID)
  context.commit('SYNC_DATA')
}

const TOGGLE_ACCESSORY = (context, accID) => {
  context.commit('TOGGLE_ACCESSORY', accID)
  context.commit('SYNC_DATA')
}

const SET_LAYOUT = (context, layoutID) => {
  context.commit('SET_LAYOUT', layoutID)
  context.commit('SYNC_DATA')
}

const SHOW_DIALOG_THANK_YOU = context => {
  context.commit('CLOSE_ALL_DIALOGS')
  context.commit('SHOW_DIALOG_THANK_YOU')
}

const SHOW_GENERIC_DIALOG = (context, content) => {
  context.commit('CLOSE_ALL_DIALOGS')
  context.commit('SHOW_GENERIC_DIALOG', content)
}

const SET_DATA_FROM_CODE = (context, dataFromCode) => {
  context.commit('SHOW_GENERIC_DIALOG', { text: 'Ładujemy Twoją <br />konfigurację MESI mTABLET ...', loading: true })
  // console.warn('CODE DATA: ', dataFromCode)

  for (const id of dataFromCode.selected.extensionsModules) {
    context.commit('TOGGLE_EXTENSION_MODULE', id, true)
    context.commit('SYNC_DATA')
  }

  for (const id of dataFromCode.selected.applications) {
    context.commit('TOGGLE_APPLICATION', id, true)
    context.commit('SYNC_DATA')
  }

  for (const id of dataFromCode.selected.layouts) {
    context.commit('SET_LAYOUT', id)
    context.commit('SYNC_DATA')
  }

  for (const id of dataFromCode.selected.accessories) {
    context.commit('TOGGLE_ACCESSORY', id, true)
    context.commit('SYNC_DATA')
  }

  context.commit('CHANGE_STEP', 'last')
  context.commit('COMPLETE_STEPS_ON_SHARE')

  const tm = setTimeout(() => {
    context.commit('CLOSE_GENERIC_DIALOG')
    clearTimeout(tm)
  }, 2000)
}

const INIT_DATA = (context) => {
  // context.commit('INIT')
  context.commit('GENERATE_BY_ID_OBJECTS')
}

export default {
  CHANGE_FIELD,
  ADJUST_ABI_TBI,
  READJUST_ABI_TBI,
  TOGGLE_EXTENSION_MODULE,
  TOGGLE_APPLICATION,
  TOGGLE_ACCESSORY,
  SET_LAYOUT,
  SHOW_DIALOG_THANK_YOU,
  SHOW_GENERIC_DIALOG,
  SET_DATA_FROM_CODE,
  INIT_DATA
}
