<template>
  <v-app :class="largeScreensClass">
    <div ref="mainAppDiv" class="fill-height" :class="largeScreensClass">
      <router-view />
    </div>
    <vue-cookie-accept-decline
      :element-id="'cookieNotice'"
      :debug="false"
      :position="'bottom-left'"
      :type="'floating'"
      :disable-decline="false"
      :transition-name="'slideFromBottom'"
      @status="cookieStatus"
      @clickedAccept="cookieClickedAccept"
      @clickedDecline="cookieClickedDecline"
    >
      <div
        slot="message"
        class="cookie-message"
      >
        Ta strona przechowuje pliki cookies na Twoim komputerze. Te pliki cookie służą do gromadzenia informacji o tym, w jaki sposób korzystasz z naszej witryny internetowej i pozwalają nam Cię zapamiętać. Używamy tych informacji, aby ulepszyć i dostosować sposób przeglądania oraz do celów analitycznych i wskaźników dotyczących naszych gości, zarówno na tej stronie, jak i w innych mediach. Aby dowiedzieć się więcej o plikach cookie, których używamy, zobacz naszą <a
          class="privacy-link"
          href="https://www.mesimedical.com/privacy-policy/"
          target="_blank"
        >Polityka prywatności</a>.
      </div>

      <div slot="declineContent">
        Odrzuć
      </div>

      <div slot="acceptContent">
        Akceptuj
      </div>
    </vue-cookie-accept-decline>
  </v-app>
</template>

<style lang="scss" scoped>
#app {
  &.large-screens {
    height: 100vh;
  }
}
</style>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MainApp',
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@graph': [
        {
          '@type': 'Organization',
          '@id': 'https://www.mesimedical.com/#organization',
          name: 'MESI, Ltd.',
          url: 'https://www.mesimedical.com/',
          logo: {
            '@type': 'ImageObject',
            '@id': 'https://www.mesimedical.com/assets/MESI-logo.svg',
            url: 'https://www.mesimedical.com/assets/MESI-logo.svg',
            contentUrl: 'https://www.mesimedical.com/assets/MESI-logo.svg'
          },
          sameAs: [
            'https://www.facebook.com/MESIdoo/',
            'https://www.linkedin.com/company/mesi-si/',
            'https://twitter.com/MESImedical'
          ]
        },
        {
          '@type': 'WebSite',
          '@id': 'https://configurator.mesimedical.com/#website',
          url: 'https://configurator.mesimedical.com/',
          name: 'MESI mTABLET Configurator',
          description: 'Skonfiguruj swój osobisty system MESI mTABLET.',
          inLanguage: 'pl-PL',
          publisher: {
            '@id': 'https://www.mesimedical.com/#organization'
          },
          copyrightHolder: { '@id': 'https://www.mesimedical.com/#organization' }
        }
      ]
    }
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'MESI mTABLET Configurator',
    // all titles will be injected into this template
    titleTemplate: '%s | Skonfiguruj swój osobisty system MESI mTABLET',
    meta: [
      { name: 'description', content: 'Skonfiguruj swój osobisty system MESI mTABLET' },
      { name: 'twitter:card', content: 'MESI mTABLET Configurator' },
      { name: 'twitter:title', content: 'MESI mTABLET Configurator' },
      { name: 'twitter:description', content: 'Skonfiguruj swój osobisty system MESI mTABLET' },
      { property: 'og:title', content: 'MESI mTABLET Configurator' },
      { property: 'og:site_name', content: 'MESI mTABLET Configurator' },
      { property: 'og:type', content: 'website' },
      { property: 'og:description', content: 'Skonfiguruj swój osobisty system MESI mTABLET' }
    ]
  },
  data () {
    return {
      statusCookie: null
    }
  },
  created () {
    if (this.$router.currentRoute.name !== 'Code' && this.$router.currentRoute.name !== 'Start') {
      this.$router.push('start')
    }

    this.onWindowResize = this.debounce(this.onWindowResize)
    this.updateNegativeMargin = this.debounce(this.updateNegativeMargin)
    this.updateVisualLine = this.debounce(this.updateVisualLine)
    this.updateStepHeight = this.debounce(this.updateStepHeight)
  },
  mounted () {
    window.addEventListener('resize', this.onWindowResize)
    this.$nextTick(() => {
      this.onWindowResize()
      this.updateStepHeight()
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.onWindowResize)
  },
  computed: {
    ...mapGetters([
      'locale',
      'steps',
      'activeStep',
      'isLastStep',
      'selectedExtensionsModules',
      'selectedApplications',
      'selectedAccessories',
      'visualLineHeight',
      'visualBlockNegativeMargin'
    ]),
    largeScreensClass () {
      return { 'large-screens': this.isDesktop() }
    },
    stepHeights () {
      return this.steps.map((obj) => typeof obj.visualStepHeight !== 'undefined' ? obj.visualStepHeight : 0)
    }
  },
  watch: {
    $route (to, from) {
      this.path = this.locale.base ? to.path.substring(this.locale.base.length) : to.path
      this.onWindowResize()
    },
    activeStep (step) {
      this.updateStepHeight()
      this.trackScreenView('Step' + step)

      if (this.isLastStep) {
        this.$store.commit('SET_VISUAL_LINE_HEIGHT', 0)
        if (this.selectedExtensionsModules.includes('TBISYSCS4') && this.selectedExtensionsModules.includes('ABISYSCS4')) {
          this.$store.dispatch('ADJUST_ABI_TBI')
        }
      } else {
        if (this.selectedExtensionsModules.includes('TBPSYSCS4') && this.selectedExtensionsModules.includes('ABISYSCS4')) {
          this.$store.dispatch('READJUST_ABI_TBI')
        }
        this.updateVisualLine()
      }
      this.updateNegativeMargin()
    },
    stepHeights () {
      // TODO: Fires twice! should only once
      // console.log('update visual line & negative margin')
      this.updateVisualLine()
      this.updateNegativeMargin()
    },
    selectedApplications () {
      this.updateStepHeight(true)
    },
    selectedAccessories () {
      this.updateStepHeight(true)
    }
  },
  methods: {
    onWindowResize () {
      // console.log('onWindowResize inside debounce')
      this.adjustAppHeightVuex()
      this.$store.commit('REFRESH_VISUAL_BLOCK_KEY')
      this.updateStepHeight()
    },
    adjustAppHeightVuex () {
      if (!this.$vuetify.breakpoint.mdAndUp) {
        this.$store.commit('SET_APP_HEIGHT', 'auto')
        return
      }
      const h = this.$refs.mainAppDiv.clientHeight
      this.$store.commit('SET_APP_HEIGHT', h)
    },
    updateVisualLine () {
      this.$store.commit('SET_VISUAL_LINE_HEIGHT', this.stepHeights[this.activeStep])
    },
    updateStepHeight (lineAndMarginToo = false) {
      const componentName = this.steps[this.activeStep].componentName

      if (!this.$root.$refs[componentName]) {
        // console.log('$root.$refs undefined for ' + componentName)
        return
      }

      this.$store.commit('SET_STEP_VISUAL_HEIGHT', { stepIndx: this.activeStep, height: this.$root.$refs[componentName].ref.clientHeight })
    },
    updateNegativeMargin () {
      // re-set all visual step ref heights so we have latest information
      for (const componentName in this.$root.$refs) {
        const indx = this.$root.$refs[componentName].index
        const ref = this.$root.$refs[componentName].ref

        this.$store.commit('SET_STEP_VISUAL_HEIGHT', { stepIndx: indx, height: ref.clientHeight })
      }

      let hPreviousSteps = 0

      if (this.activeStep === 0 || this.isLastStep) {
        hPreviousSteps = 0
      } else {
        hPreviousSteps = this.stepHeights.reduce(this.sumStepHeights, 0)
      }

      if (this.isDesktop()) {
        this.$store.commit('SET_VISUAL_BLOCK_NEGATIVE_MARGIN_TOP', hPreviousSteps)
      } else {
        this.$store.commit('SET_VISUAL_BLOCK_NEGATIVE_MARGIN_TOP', 0)
      }
    },
    sumStepHeights (acc, currentVal, currIndx) {
      if (currIndx < this.activeStep && !isNaN(currentVal)) {
        return acc + currentVal
      }
      return acc
    },
    cookieStatus (status) {
      this.statusCookie = status
    },
    cookieClickedAccept () {
      if (this.saEvent) {
        this.saEvent('cookies-accept')
      }
      this.statusCookie = 'accept'
    },
    cookieClickedDecline () {
      if (this.saEvent) {
        this.saEvent('cookies-decline')
      }
      this.statusCookie = 'decline'
    }
  }
}
</script>

<style lang="scss">
.theme--light.v-application,
.theme--light.v-card {
  color: var(--v-primary-base) !important;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.cookie-message {
  .privacy-link {
    color: #8CC63E;
  }
}
.cookie__floating__buttons__button {
  &.cookie__floating__buttons__button--accept {
    color: #8CC63E;
    background-color: #FFFFFF;
    &:hover {
      color: #FFFFFF;
      background-color: #8CC63E;
    }
  }
  &.cookie__floating__buttons__button--decline {
    color: #EA2227;
    background-color: #FFFFFF;
    &:hover {
      color: #FFFFFF;
      background-color: #EA2227;
    }
  }
}
</style>
