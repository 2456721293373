export const getDefaultState = () => {
  const formURL = process.env.NODE_ENV === 'development' ? 'https://configurator.test/dist/inquiry' : 'https://configurator-pl.mesimedical.com/inquiry'
  const formURLSubscription = process.env.NODE_ENV === 'development' ? 'https://configurator.test/dist/subscription' : 'https://configurator-pl.mesimedical.com/subscription'

  return {
    locale: 'pl',
    APIURL: 'https://configurator-pl.mesimedical.com/api/index.php',
    FORMURL: formURL,
    FORMURL_SUBSCRIPTION: formURLSubscription,
    activeStep: 0,
    selectedField: null,
    defaultPriceMTAB: 19,
    mTabIncludedApps: ['MTABAPPPHOTO'],
    appHeight: null,
    navigationHeight: 140,
    visualBlockKey: 'vbk',
    visualBlockVisibleMobile: false,
    visualBlockNegativeMargin: 0,
    visualLineHeight: 0,
    configCode: '',
    configURL: '',
    configCodeSeparator: '-',
    extensionsModulesByID: {},
    applicationsByID: {},
    layoutsByID: {},
    accessoriesByID: {},
    interfaceData: {},
    selected: {
      extensionsModules: [],
      applications: [],
      layout: 'TABLETOP',
      accessories: []
    },
    preselected: {
      applications: [],
      accessories: []
    },
    allowed: {
      applications: [],
      accessories: []
    },
    disabled: {
      extensionsModules: [],
      layouts: ['TROLLEYECG']
    },
    snackbars: {
      selectionDisabled: false,
      selectionDisabledText: 'MESI właśnie ulepszyło Twoją konfigurację, odznaczając niepotrzebny sprzęt! Uzyskaj te same wyniki, korzystając z naszej inteligentnej aplikacji.',
      selectionDisabledTextOriginal: 'MESI właśnie ulepszyło Twoją konfigurację, odznaczając niepotrzebny sprzęt! Uzyskaj te same wyniki, korzystając z naszej inteligentnej aplikacji.',
      selectionDisabledTextAbiTbi: 'MESI właśnie ulepszyło Twoją konfigurację, wybierając tylko niezbędny sprzęt! Uzyskaj takie same wyniki przy mniejszej ilości sprzętu!',
      general: false,
      generalSnackbarText: ''
    },
    dialogs: {
      selectionDisabled: false,
      disabledItemsBySelectingAnother: [],
      generic: false,
      sendInquiry: false,
      share: false,
      thankYou: false,
      genericDialogContent: '',
      genericDialogLoading: false
    },
    allExtModData: {},
    allAppsData: {},
    allLayoutsData: {},
    allAccessoriesData: {},
    steps: [
      {
        componentName: 'ExtModules',
        name: 'Diagnostic Tools',
        complete: false,
        visualStepHeight: null,
        assetsInteractivePath: '@/assets/extModules/interactive/',
        assetsVisualPath: '@/assets/extModules/visual/'
      },
      {
        componentName: 'Apps',
        name: 'Smart Applications',
        complete: false,
        visualStepHeight: null,
        assetsInteractivePath: '@/assets/apps/interactive/',
        assetsVisualPath: '@/assets/apps/visual/'
      },
      {
        componentName: 'Layout',
        name: 'Layout',
        complete: false,
        visualStepHeight: null,
        assetsInteractivePath: '@/assets/layout/interactive/',
        assetsVisualPath: '@/assets/layout/visual/'
      },
      {
        componentName: 'Accessories',
        name: 'Accessories',
        complete: false,
        visualStepHeight: null,
        assetsInteractivePath: '@/assets/accessories/interactive/',
        assetsVisualPath: '@/assets/accessories/visual/'
      },
      {
        componentName: 'Summary',
        name: 'Summary',
        complete: false,
        visualStepHeight: null
      }
    ]
  }
}
