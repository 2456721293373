/* import allExtModData from '@/data/extensionsModules.json'
import allAppsData from '@/data/applications.json'
import allLayoutsData from '@/data/layouts.json'
import allAccessoriesData from '@/data/accessories.json' */

// import fieldOfCareData from '@/data/fields.json'
import allExtModData from '@/data/pl/extensionsModules.json'
import allAppsData from '@/data/pl/applications.json'
import allLayoutsData from '@/data/pl/layouts.json'
import allAccessoriesData from '@/data/pl/accessories.json'
import interfaceDataJson from '@/data/pl/interfaceData.json'

import mixins from '@/mixins.js'

import { getDefaultState } from './state'

// INIT first, beacause if you get to the translated Code page it redirects to start and errors ---> NOT USED anymore
const INIT = state => {
  state.allExtModData = allExtModData
  state.allAppsData = allAppsData
  state.allLayoutsData = allLayoutsData
  state.allAccessoriesData = allAccessoriesData
  state.interfaceData = interfaceDataJson
  state.steps = interfaceDataJson.steps
}

const SET_INTERFACE_TEXTS = (state, locale) => {
  state.allExtModData = allExtModData
  state.allAppsData = allAppsData
  state.allLayoutsData = allLayoutsData
  state.allAccessoriesData = allAccessoriesData
  state.interfaceData = interfaceDataJson
  state.steps = interfaceDataJson.steps
}

const SET_LOCALE = (state, locale) => {
  state.locale = locale
}

const PREV_STEP = state => {
  state.activeStep = Math.max(0, state.activeStep - 1)
}

const NEXT_STEP = state => {
  // we dont really need "complete" feature of this do we since moving back should still show
  // which is active, not which is "complete"
  state.steps[state.activeStep].complete = true
  state.activeStep = Math.min(state.steps.length, state.activeStep + 1)
}

const CHANGE_STEP = (state, indx) => {
  if (indx === 'last') {
    state.activeStep = state.steps.length - 1
  } else {
    const st = parseInt(indx)
    if (st && st >= 0 && st < state.steps.length) {
      state.activeStep = indx
    } else {
      state.activeStep = 0
    }
  }
}

/**
 * If you open shared url, complete previous steps automatically
 */
const COMPLETE_STEPS_ON_SHARE = state => {
  for (let i = 0; i < state.steps.length - 1; i++) {
    state.steps[i].complete = true
  }
}

/**
 * If ABI + TBI are selected delete TBI and add TBP
 */
const ADJUST_ABI_TBI = state => {
  state.selected.extensionsModules = mixins.removeItemsFromArray(state.selected.extensionsModules, ['TBISYSCS4'])
  state.selected.extensionsModules.push('TBPSYSCS4')
  state.disabled.extensionsModules = mixins.removeItemsFromArray(state.disabled.extensionsModules, ['TBPSYSCS4', 'BPSYSCS4', 'BPSYS'])
  state.disabled.extensionsModules.push('TBISYSCS4')
  state.snackbars.selectionDisabledText = state.interfaceData.snackbars.selectionDisabledTextAbiTbi
  // console.log('SELECTION Disabled FIRST: ', state.interfaceData)
  state.snackbars.selectionDisabled = true
}

/**
 * If you go back to selection and TBP is selected, delete TBP and add TBI to have ABI + TBI
 */
const READJUST_ABI_TBI = state => {
  const tbiExtDisabled = ['TBPSYSCS4', 'BPSYSCS4', 'BPSYS']
  state.selected.extensionsModules = mixins.removeItemsFromArray(state.selected.extensionsModules, ['TBPSYSCS4'])
  state.selected.extensionsModules.push('TBISYSCS4')
  state.disabled.extensionsModules = mixins.removeItemsFromArray(state.disabled.extensionsModules, ['TBISYSCS4'])
  state.disabled.extensionsModules.push(...tbiExtDisabled)
}

const UPDATE_SELECTED_FIELD = (state, systemId) => {
  state.selectedField = systemId
}

const TOGGLE_EXTENSION_MODULE = (state, moduleID, addOnly = false) => {
  const selectedExtMods = state.selected.extensionsModules.slice()
  const index = selectedExtMods.indexOf(moduleID)
  const add = (index < 0)

  const extMod = state.extensionsModulesByID[moduleID]
  if (typeof extMod === 'undefined') {
    return
  }

  if (add) {
    // console.log('ADD: ' + moduleID)
    state.selected.extensionsModules.push(moduleID)
  } else {
    // console.log('Remove: ' + moduleID)
    if (addOnly) {
      return
    }
    state.selected.extensionsModules.splice(index, 1)
  }
}

const SYNC_DATA = state => {
  // const p1 = performance.now()

  let includedApps = []
  let allowedApps = []

  let includedAccessories = []
  let allowedAccessories = []

  let disabledExtMods = []

  for (const mID of state.selected.extensionsModules) {
    const meObj = state.extensionsModulesByID[mID]

    if (typeof meObj === 'undefined') {
      return
    }

    disabledExtMods = mixins.concatAndRemoveDuplicates(disabledExtMods, meObj.extensionsModules.disabled)
  }

  state.disabled.extensionsModules = disabledExtMods

  // Those that are disabled should not be on selected list now
  const removed = mixins.arrayIntersection(state.selected.extensionsModules, state.disabled.extensionsModules)
  state.selected.extensionsModules = mixins.removeItemsFromArray(state.selected.extensionsModules, state.disabled.extensionsModules)
  if (removed.length > 0) {
    state.dialogs.disabledItemsBySelectingAnother = removed
    state.snackbars.selectionDisabled = true
  }

  for (const mID of state.selected.extensionsModules) {
    const meObj = state.extensionsModulesByID[mID]

    if (typeof meObj === 'undefined') {
      return
    }

    /**
     * Update preselected and allowed apps/accessories
     */
    includedApps = mixins.concatAndRemoveDuplicates(includedApps, meObj.applications.included)
    includedAccessories = mixins.concatAndRemoveDuplicates(includedAccessories, meObj.accessories.included)

    allowedApps = mixins.concatAndRemoveDuplicates(allowedApps, meObj.applications.allowed)
    allowedAccessories = mixins.concatAndRemoveDuplicates(allowedAccessories, meObj.accessories.allowed)
  }

  /**
   * Update preselected, selected & allowed APPS
   */
  const keepSelectedApps = mixins.arrayIntersection(state.selected.applications.slice(), allowedApps)
  state.preselected.applications = includedApps
  state.selected.applications = mixins.concatAndRemoveDuplicates(keepSelectedApps, includedApps)
  state.allowed.applications = allowedApps

  /**
   * Update Layouts
   * Enables/disabled TROLLEYECG based on ECGSYSCS4 extension/module
   */
  if (state.selected.extensionsModules.includes('ECGSYSCS4')) {
    state.disabled.layouts = mixins.removeItemsFromArray(state.disabled.layouts, ['TROLLEYECG'])
  } else {
    if (!state.disabled.layouts.includes('TROLLEYECG')) {
      state.disabled.layouts.push('TROLLEYECG')

      // if this was previously selected, select normal TROLLEY instead
      // TODO: alert the user?
      if (state.selected.layout === 'TROLLEYECG') {
        state.selected.layout = 'TROLLEY'
      }
    }
  }

  /**
   * Update preselected, selected & allowed ACCESSORIES
   */
  const keepSelectedAccessories = mixins.arrayIntersection(state.selected.accessories.slice(), allowedAccessories)
  state.preselected.accessories = includedAccessories
  state.selected.accessories = mixins.concatAndRemoveDuplicates(keepSelectedAccessories, includedAccessories)
  state.allowed.accessories = allowedAccessories

  // keep Layouts in mind for accessories too

  // const p2 = performance.now()
  // console.log(p2 - p1 + ' ms')
}

const TOGGLE_APPLICATION = (state, appID, onlyAdd = false) => {
  const selectedApps = state.selected.applications.slice()
  const index = selectedApps.indexOf(appID)
  const add = (index < 0)

  const appObj = state.applicationsByID[appID]
  if (typeof appObj === 'undefined') {
    return
  }

  if (add) {
    state.selected.applications.push(appID)
    // console.log('ADDING app: ', appID)
    if (appObj.noticeText) {
      state.snackbars.generalSnackbarText = appObj.noticeText
      state.snackbars.general = true
    }
  } else {
    if (onlyAdd) {
      return
    }

    state.selected.applications.splice(index, 1)
  }
}

const SET_LAYOUT = (state, layoutID) => {
  const meObj = state.layoutsByID[layoutID]
  state.selected.layout = layoutID
  state.selected.accessories = meObj.accessories.included
}

const TOGGLE_ACCESSORY = (state, accID, onlyAdd = false) => {
  const selected = state.selected.accessories.slice()
  const index = selected.indexOf(accID)
  const add = (index < 0)

  const acc = state.accessoriesByID[accID]
  if (typeof acc === 'undefined') {
    return
  }

  if (add) {
    state.selected.accessories.push(accID)
    // console.log('ADDING accessory: ', accID)
  } else {
    if (onlyAdd) {
      return
    }

    state.selected.accessories.splice(index, 1)
  }
}

const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

const SET_CONFIG_CODE = (state, code) => {
  state.configCode = code
}

const SET_CONFIG_URL = (state, url) => {
  state.configURL = url
}

const SET_VISUAL_LINE_HEIGHT = (state, newHeight) => {
  // console.log('SET_VISUAL_LINE_HEIGHT() to ' + newHeight)
  state.visualLineHeight = newHeight
}

const SET_APP_HEIGHT = (state, newHeight) => {
  // console.warn('SET_APP_HEIGHT: ' + newHeight)
  state.appHeight = newHeight
}

/**
 * Needs to be called when changing steps since a step can have a different navigation
 * (last step for example - and also when changing from 4 back to any we have to re-adjust)
 */
const SET_NAV_HEIGHT = (state, newHeight) => {
  state.navigationHeight = newHeight
}

const SHOW_SEND_INQUIRY_DIALOG = state => {
  state.dialogs.sendInquiry = true
}

const SHOW_DIALOG_THANK_YOU = state => {
  state.dialogs.thankYou = true
}

const SHOW_GENERIC_DIALOG = (state, obj) => {
  if (typeof obj.text === 'undefined') {
    return
  }
  state.dialogs.genericDialogContent = obj.text
  state.dialogs.genericDialogLoading = typeof obj.loading !== 'undefined' ? obj.loading : false
  state.dialogs.generic = true
}

const SHOW_SHARE_DIALOG = state => {
  state.dialogs.share = true
}

const CLOSE_SHARE_DIALOG = state => {
  state.dialogs.share = false
}

const CLOSE_GENERIC_DIALOG = state => {
  state.dialogs.generic = false
}

const CLOSE_SELECTION_DISABLED_DIALOG = state => {
  state.dialogs.selectionDisabled = false
}

const CLOSE_ALL_DIALOGS = state => {
  state.genericDialogContent = ''
  state.dialogs.generic = false
  state.dialogs.sendInquiry = false
  state.dialogs.thankYou = false
}

const REFRESH_VISUAL_BLOCK_KEY = state => {
  // console.warn('REFRESH_VISUAL_BLOCK_KEY')
  state.visualBlockKey = (Date.now() + Math.random())
}

const SET_VISUAL_BLOCK_NEGATIVE_MARGIN_TOP = (state, marginTopN) => {
  state.visualBlockNegativeMargin = marginTopN
  // console.log('NEW MARGIN TOP Negative: ', marginTopN)
}

const SET_STEP_VISUAL_HEIGHT = (state, obj) => {
  if (typeof obj.stepIndx === 'undefined' || typeof obj.height === 'undefined') {
    return
  }
  // console.log('set step ', obj.stepIndx, ' in vuex visual height to ', obj.height)
  state.steps[obj.stepIndx].visualStepHeight = obj.height
}

const SET_PRICE_FINAL = (state, price) => {
  state.pricesAfterMtab.price = price
}

const SET_PRICE_GREEN_FINAL = (state, price) => {
  state.pricesAfterMtab.priceGreenPackage = price
}

const SET_PRICE_PREMIUM_FINAL = (state, price) => {
  state.pricesAfterMtab.pricePremiumPackage = price
}

const SET_SELECTED = (state, selectedObject) => {
  // console.log(' - - - - - update SELECTED: ', selectedObject)
  Object.assign(state.selected, selectedObject)
}

const SET_PRESELECTED = (state, preselectedObject) => {
  // console.log(' - - - - - update PRE-SELECTED: ', preselectedObject)
  Object.assign(state.preselected, preselectedObject)
}

/**
 * So we can access object data by ID easily across the app
 */
const GENERATE_BY_ID_OBJECTS = state => {
  for (const obj of state.allExtModData) {
    state.extensionsModulesByID[obj.id] = obj
  }

  for (const obj of state.allAppsData) {
    state.applicationsByID[obj.id] = obj
  }

  for (const obj of state.allLayoutsData) {
    state.layoutsByID[obj.id] = obj
  }

  for (const obj of state.allAccessoriesData) {
    state.accessoriesByID[obj.id] = obj
  }
}

const SET_DEFAULT_MTAB_PRICE = state => {
  if (state.extensionsModulesByID.MTABSYSW) {
    state.defaultPriceMTAB = state.extensionsModulesByID.MTABSYSW.subsPriceEur
  }
}

const HIDE_SELECTION_DISABLED_SNACKBAR = state => {
  state.snackbars.selectionDisabled = false
  // console.log('SELECTION Disabled SECOND: ', state.interfaceData)
  state.snackbars.selectionDisabledText = state.interfaceData.snackbars.selectionDisabledTextOriginal
}

const HIDE_GENERAL_SNACKBAR = state => {
  state.snackbars.general = false
}

const SET_VISUAL_BLOCK_VISIBLE_MOBILE = (state, visible) => {
  state.visualBlockVisibleMobile = visible
}

export default {
  INIT,
  SET_INTERFACE_TEXTS,
  SET_LOCALE,
  PREV_STEP,
  NEXT_STEP,
  CHANGE_STEP,
  COMPLETE_STEPS_ON_SHARE,
  ADJUST_ABI_TBI,
  READJUST_ABI_TBI,
  SET_CONFIG_CODE,
  SET_CONFIG_URL,
  UPDATE_SELECTED_FIELD,
  TOGGLE_EXTENSION_MODULE,
  SYNC_DATA,
  TOGGLE_APPLICATION,
  SET_LAYOUT,
  TOGGLE_ACCESSORY,
  RESET_STATE,
  SET_VISUAL_LINE_HEIGHT,
  SET_APP_HEIGHT,
  SET_NAV_HEIGHT,
  SHOW_SEND_INQUIRY_DIALOG,
  SHOW_DIALOG_THANK_YOU,
  SHOW_GENERIC_DIALOG,
  CLOSE_GENERIC_DIALOG,
  SHOW_SHARE_DIALOG,
  CLOSE_SHARE_DIALOG,
  CLOSE_SELECTION_DISABLED_DIALOG,
  CLOSE_ALL_DIALOGS,
  REFRESH_VISUAL_BLOCK_KEY,
  SET_VISUAL_BLOCK_NEGATIVE_MARGIN_TOP,
  SET_STEP_VISUAL_HEIGHT,
  SET_PRICE_FINAL,
  SET_PRICE_GREEN_FINAL,
  SET_PRICE_PREMIUM_FINAL,
  SET_SELECTED,
  SET_PRESELECTED,
  GENERATE_BY_ID_OBJECTS,
  SET_DEFAULT_MTAB_PRICE,
  HIDE_SELECTION_DISABLED_SNACKBAR,
  HIDE_GENERAL_SNACKBAR,
  SET_VISUAL_BLOCK_VISIBLE_MOBILE
}
